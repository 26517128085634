.container {
  display: flex;
  flex-direction: row;
}

nav {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 200px;
  border-right: 1px solid grey;
}

nav > ul {
  margin: 5px;
  padding: 0;
}

nav > ul > a {
  display: flex;
  margin: 3px;
  padding: 5px;
  width: calc(100% - 16px);
  background-color: #fff;
  border-bottom: 1px solid #bbf;
}

nav > ul > a:hover {
  padding-left: 15px;
  width: calc(100% - 26px);
  border-bottom: 1px solid #00f;
}

nav > ul > a.active {
  padding-left: 15px;
  width: calc(100% - 26px);
  border-bottom: 1px solid #00f;
  font-weight: bold;
}